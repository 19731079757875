<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Room History</h3>
      <!-- <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0);">Table</a></li>
            <li class="breadcrumb-item active" aria-current="page">Advanced Table</li>
          </ol>
        </nav> -->
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-modal ref="room-modal" hide-footer :no-close-on-backdrop="true" id="modallg" :title="selectedRoom.name"
              size="lg" :footer-bg-variant="'info'">
              <div class="col-md-12">
                <div class="col-md-12 grid-margin stretch-card fc-scroller fc-day-grid-container">
                  <h3 class="text-danger" v-if="Object.keys(selectedRoom).length > 0 &&
                    selectedRoom?.roomInitialInformation.length == 0
                    ">
                    No Information Found
                  </h3>
                  <div v-if="Object.keys(selectedRoom).length > 0 &&
                    selectedRoom?.roomInitialInformation.length != 0
                    " class="card">
                    <div class="card-body" style="overflow: hidden scroll; height: 250px">
                      <!-- <h4 class="card-title"></h4> -->
                      <div v-for="info in this.selectedRoom.roomInitialInformation" :key="info.id">
                        <p class="text-gray">
                          {{ info.createdAt | moment("ll") }}
                        </p>
                        <ul class="bullet-line-list pb-3">
                          <li>
                            <div class="d-flex align-items-center justify-content-between">
                              <div class="d-flex">
                                <div class="ml-3">
                                  <h6 class="mb-0">
                                    {{
                                      info.player.firstName +
                                      info.player.lastName
                                    }}
                                  </h6>
                                  <small class="text-muted">
                                    {{ info.job.name }}
                                  </small>
                                </div>
                              </div>
                              <div v-if="selectedRoom?.scoreInformation.length == 0">
                                <small class="d-block mb-0 text-danger">No Score Found</small>
                              </div>
                              <div v-if="selectedRoom?.scoreInformation.length > 0">
                                <small class="d-block mb-0">Score</small>
                                <small class="text-muted d-block">{{
                                  selectedRoom.scoreInformation.filter(
                                    (score) => score.playerId == info.playerId
                                  )[0].score
                                }}</small>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <b-button class="mt-3 subbtn float-end" variant="outline-success" @click="() => {this.selectedJobId = null, this.$refs['job-modal'].hide();}">close</b-button> -->
            </b-modal>
            <h4 class="card-title"></h4>
            <div class="float-right">
              <!-- search field -->
              <b-input v-model="filter" placeholder="Search Room" id="room-search" style="padding: 10px"></b-input>
            </div>
            <b-table :items="items" id="table-list" responsive :per-page="perPage" :current-page="currentPage"
              :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" show-empty :filter="filter" striped
              hover>
              <!-- when no item found -->
              <template #empty="">
                <p class="text-muted text-center">{{ noItems }}</p>
              </template>
              <!-- status -->
              <template v-slot:cell(status)="data">
                <span v-html="data.value"></span>
              </template>

              <!-- players -->
              <template v-slot:cell(players)="data">
                <!-- <b-badge pill variant="outline-success">{{
                  data.item.players
                }}</b-badge> -->

                <span v-html="data.value"></span>
              </template>

              <template v-slot:cell(action)="data">
                <!-- Actions -->

                <i v-b-modal.modallg v-b-tooltip.html.top title="Players" variant="primary"
                  @click="viewDetail(data.item.id)" :ref="'btn' + data.index"
                  class="mr-2 fa fa-users text-info icon-sm"></i>

                <span v-html="data.value"></span>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      filter: "",
      sortable: true,
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        // { key: "players", sortable: true },
        { key: "status", sortable: true },
        { key: "createdAt", sortable: true },
        { key: "updatedAt", sortable: true },
        { key: "action", sortable: true },
      ],
      items: [],
      noItems: null,
      selectedRoom: {},
    };
  },
  computed: {
    ...mapGetters(["getRoomScoreInfo"]),
    rows() {
      return this.items.length;
    },
  },
  methods: {
    ...mapActions(["setRoomScoreInfo"]),
    setItems(data) {
      data.forEach((element) => {
        let obj = {};
        obj.id = element.id;
        obj.name = element.name;
        obj.status = `<label variant='outline' class="badge ${element.isActive == true ? "badge-success" : "badge-danger"
          }">${element.isActive == true ? "Active" : "Expired"}</label>`;
        obj.players = element.roomInitialInformation.length;
        obj.createdAt = moment(element.createdAt).format("ll");
        obj.updatedAt = moment(element.updatedAt).format("ll");

        this.items.push(obj);
      });
    },
    viewDetail(id) {
      this.selectedRoom = {};
      this.selectedRoom = this.getRoomScoreInfo.filter(
        (room) => room.id == id
      )[0];
      console.log("sel", this.selectedRoom);
    },
  },
  async mounted() {
    
    await this.setRoomScoreInfo();
    this.getRoomScoreInfo.length > 0
      ? this.setItems(this.getRoomScoreInfo)
      : (this.noItems = "No Room Found.");

    console.log(this.getRoomScoreInfo);
  },
};
</script>
